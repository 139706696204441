import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import "./MasonryLayout.scss"

const MasonryLayout = ({ content }) => {
  
  return (
  <div className="masonryLayout">
    <ResponsiveMasonry
      columnsCountBreakPoints={{350: 1, 1000: 2}}
    >
      <Masonry gutter={"1em"}>
        {content}
      </Masonry>
    </ResponsiveMasonry>
  </div>
  )
}

export default MasonryLayout;
