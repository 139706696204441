import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby';
import './ProjectTile.scss'

const ProjectTile = ({ title, tileImage, link, alt }) => {

  return (
    <Link to={`/${link}`}>
      <article className="tile">
        <div className="project-header">
          <p className='project-title'>{title}</p>
        </div>
        <GatsbyImage 
          image={getImage(tileImage)}
          alt={alt}  
        />
      </article>
    </Link>
  )
}

export default ProjectTile; 
