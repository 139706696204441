import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Components/Layout';
import ProjectTile from '../Components/ProjectTile';
import MasonryLayout from '../Components/MasonryLayout';

const music = ({ data }) => {

  const musicList = data.allMdx.edges.map(musicProject => {
    return (
      <ProjectTile 
        link={musicProject.node.slug}
        title={musicProject.node.frontmatter.title} 
        tileImage={musicProject.node.frontmatter.tileImage}
        alt={musicProject.node.frontmatter.tileAlt}
        id={musicProject.node.id}
        key={musicProject.node.id}
      />
    )
  })

  return (
    <Layout>
      <MasonryLayout content={musicList} />
    </Layout>
  );
}

export default music; 

export const data = graphql` {
  allMdx(
    sort: {fields: [frontmatter___order], order: DESC}
    filter: {frontmatter: {tags: {eq: "music"}}}
  ) {
    edges {
      node {
        slug
        id
        frontmatter {
          title
          tileAlt
          tileImage {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}`
